.home {
  &-gridlayout-container {
    // Screen breakpoints - we had to do it manually due to @extend doesnt work in @media queries
    // Screens minimum 1300px
    @media screen and (min-width: 1300px) {
      margin: 40px 155px 80px 155px;
    }

    // Screens btwn 1000px and 1299px
    @media screen and (min-width: 1000px) and (max-width: 1299px) {
      margin: 40px 60px 80px 60px;
    }

    // Screens btwn 0 and 999px
    @media screen and (max-width: 999px) {
      margin: 40px 36px 80px 36px;
    }

    & .cardContainer_container {
      @extend .mt-16;
      &:first {
        @extend .mt-0;
      }
    }
  }
}
