.textareaContainer {
  color: $Copy;
  resize: none;
  border: 1px solid $Inactive;
  border-radius: 6px;
  outline: none;
  @extend .p-12;
  &:focus,
  &:focus-within {
    border-color: $Brand;
  }
}
.error {
  border-color: $Alert;
}
