// input text padding
$InputTextPadding: 13px;
$InputTextHeight: 44px;
$InputTextBorderRadius: 6px;

.input {
  padding: $InputTextPadding;
  height: $InputTextHeight;
  width: 100%;
  border-radius: $InputTextBorderRadius;
  box-sizing: border-box;
  border: 1px solid $Inactive;
  color: $Copy;
  background: $Surface;
  &:focus,
  &:focus-visible {
    border: 1px solid $Brand;
    outline: none;
  }
  &::placeholder {
    color: $Label;
  }
  &.input-withIcon {
    padding-left: 42px;
  }
  &.disabled,
  &:disabled {
    color: $Inactive;
    border: 1px solid $Inactive;
  }
}
.input-container-withIcon {
  display: flex;
  align-items: center;
  &:not(.disabled-input-container) > svg {
    @extend .icon-copy-muted;
  }
  &.disabled-input-container > svg {
    @extend .icon-inactive;
  }
  & > svg {
    height: 44px;
    width: 20px;
    position: absolute;
    margin-left: 10px;
  }
}
