@import './../variables';
// Specify all background colors
$buttonBgs: (
  'info': $Info,
  'primary': $Primary,
  'danger': $Alert,
);
// For Raised buttons Specify the key-value pair of how the text-color looks like
$buttonColors: (
  'info': $Surface,
  'primary': $Surface,
  'danger': $Surface,
);

.button {
  @extend .pl-16;
  @extend .pr-16;
  @extend .pt-8;
  @extend .pb-8;
  border-radius: 6px;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.75;
  }

  // Types
  &.button-flat {
    background-color: #ffffff00;
    // colors
    @each $name, $color in $buttonBgs {
      &.button-#{$name} {
        & > * {
          color: $color;
        }
        &:hover:not(:disabled) {
          background-color: transparentize($color, 0.85);
        }
      }
    }
  }

  &.button-raised {
    // colors
    @each $name, $color in $buttonBgs {
      &.button-#{$name} {
        background: $color;
        & > * {
          color: map-get($map: $buttonColors, $key: $name);
        }
      }
    }
  }

  // icon button
  &.icon-button {
    & svg {
      display: inline;
      @extend .mr-8;
      @extend .icon-small;
    }
  }

  // global style for label
  & > .p1 {
    display: inline;
  }
  &.action-button > .p1 {
    @extend .bold;
  }
}
