.softBoxedIcon_container {
  background: $Inactive;
  border: 1px solid $Inactive;
  border-radius: 12px;
  width: 40px !important;
  height: 40px !important;
  @extend .flex-row;

  @extend .justify-content-center;
  @extend .align-items-center;
}
