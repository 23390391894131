// AutoComplete
$defaultAutoCompleteWidth: 452px;
$ExtraAutoCompleteWidth: 44px;
$defaultAutoCompleteListHeight: 184px;

.autoComplete-container {
  width: $defaultAutoCompleteWidth;
}

.autoCompelete {
  @extend .input;
  padding-left: $ExtraAutoCompleteWidth;
}

.search-data-list {
  position: absolute;
  overflow-y: scroll;
  background: $Surface;
  z-index: 20;
  border: 1px solid $Inactive;
  box-shadow: 0px 0px 15px rgba(46, 25, 130, 0.12);
  border-radius: $InputTextBorderRadius;
  width: $defaultAutoCompleteWidth;
  height: $defaultAutoCompleteListHeight;
}

.search-data-list > li {
  list-style-type: none;
  cursor: pointer;
  &:hover {
    background: $Hover;
  }
}

.search-icon {
  top: 14px;
  left: 14px;
}


.search-plus-icon {
  height: $InputTextHeight;
  width: $InputTextHeight;
  top: 0;
  right: 0;
  border-radius: $InputTextBorderRadius;
}
