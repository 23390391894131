.skillsEditor {
    max-height: 860px;   
      &_container {
        max-height: 300px;
        min-height: 250px;
        overflow-y: auto;
    }

    &_header {
        @extend .flex-row;
         @extend .align-items-center;
        @extend .justify-content-between;
        @extend .mt-24;

        border-bottom: 2px solid $Inactive;
        padding-bottom: 5px;
        .p2 {
            width: 250px;
            color: $Copy-Muted;
         }
        .p2:not(:first-child){
            width: 200px;
             @extend .pl-16
             
        }
        p:last-of-type {
            width: 37px !important;
          }
    }
}