.mouseTooltip_container {
  cursor: pointer;
  display: table;
  border-radius: 6px;
   padding: 12px;
   
  &:hover{
    background-color: $Hover;
  }
}
.tooltipText {
   background-color: $Copy;
  color: $Surface;
  padding: 4px 6px;
  border-radius: 4px;
  opacity: 1;
  
}
