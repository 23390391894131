.profileEditor {
  .search-icon {
    top: 31px;
  }
  .maxTravelTime {
    flex: 1;
    text-align: center;
  }

  .location {
    &:focus {
      @extend .pl-20;
      background-color: red;
    }
  }
  .location-container {
     
    .location {
        transform: translate(28px, 30px)  ;
      }
      :not(.disabled-field):focus-within > .location {
        transform: translate(0px, 0) !important;
      }
   
  }
}
