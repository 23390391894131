.experience-list-item {
  @extend .mt-24;
  @extend .mb-12;
  display: grid;
  grid-template-columns: 46px 1fr;
  column-gap: 12px;

  & .experience-list-item-icon {
    @extend .pt-12;
  }
  & .experience-list-item-content {
    @extend .pt-12;
    @extend .pb-12;
  }
}
