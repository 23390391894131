* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::before {
  box-sizing: inherit;
  font-family: "Rubik", sans-serif;
  line-height: 17px;
}

html {
  box-sizing: border-box;
  font-size: 80%;
}

body {
  background-color: $Surface;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: $Label;
  border: 4px solid $Surface;
  box-shadow: none;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  border: 4px solid $Label;
}
