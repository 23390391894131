.field-control-container {
  display: flex;
  flex-direction: column;
  @extend .mt-8;

  & .field-control-error-container {
    &.show-error {
      filter: opacity(1);
    }
    &.hide-error {
      filter: opacity(0);
    }
  }

  & > label {
    pointer-events: none;
    position: relative;
    transform: translate(16px, 30px);
    margin: 0 4px;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    color: $Label;
    z-index: 100;
  }

  &:not(.disabled-field).has-error {
    & label {
      color: $Label;
    }
  }

  
  // if you need to add additional control styling use field-control-[COMPONENTNAME]
  &.field-control-TextInput {
    &:not(.disabled-field).has-error input.input {
      color: $Alert;
      border: 1px solid $Alert;
    }
  }
  &.field-control-Dropdown {
    & > label {
      transform: translate(16px, 32px);
    }
    &:not(.disabled-field).has-error .dropdown_selectedValue {
      color: $Alert;
      border: 1px solid $Alert;
      border-radius: 6px;
    }
  }
  &.field-control-Datepicker {
    @extend .field-control-TextInput;
    & > label {
      transform: translate(42px, 32px);
    }
    &.disabled-field .datepicker-input {
    }
  }

  // focus & has value state of the label

  &.has-value > label,
  &:not(.disabled-field):focus-within > label {
    transform: translate(0, 0px);
  }

  &.disabled-field {
    & > label {
      color: $Inactive;
    }
  }
}

.dropdown_options {
  z-index: 110;
}
