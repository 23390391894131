.dropdown {
  &_container {
    cursor: pointer;
    position: relative;
    @extend .mr-3;
    @extend .ml-3;
  }
  &_container.disabled {
    cursor: unset;
  }

  &_options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 110;
    background: $Surface;
    border-radius: 6px;
    border: 1px solid $Inactive;
    @extend .p-8;
    .option {
      border-radius: 6px;
      &:hover {
        background-color: $Hover;
      }
      @extend .flex-row;
      @extend .align-items-center;
    }
  }
  &_selectedIcon {
    width: 20px;
    height: 20px;
    @extend .m-6;
  }

  &_container:not(.bordered) &_selectedValue {
    border: 1px solid $Surface;
  }
  &_container.bordered &_selectedValue {
    border: 1px solid $Inactive;
  }

  &_selectedValue {
    border-radius: 6px;

    @extend .flex-row;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .pl-12;
    @extend .pr-12;
    @extend .pt-10;
    @extend .pb-10;

    border: 1px solid $Transparent;
    border-radius: 6px;
    height: 48px;
    &.focused {
      border: 1px solid $Brand !important;
    }
    &.hasError {
      border: 1px solid $Alert !important;
    }
  }
}
