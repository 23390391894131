.page-container {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  & > header {
  }
  & > .page-container-content {
    background-color: $Surface-Muted;
  }
  & > footer {
    margin-top: auto;
  }
}
