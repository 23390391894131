@import './../variables';

.signin-container {
  & .signin-header {
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
  }
  & > * {
    color: $Primary;
  }
  background: $Brand;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  &::before {
    position: absolute;
    content: ' ';
    background-image: url('/assets/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -110;
  }
  & .intro-paragraph {
    margin: 0 3em;
  }
  & .signin-content {
    z-index: 1000000;
    background: $Surface;
    box-shadow: 0px 0px 30px rgba(46, 25, 130, 0.05);
    border-radius: 12px;
    // default config depending on breakpoint
    text-align: center;
    width: 50%;
    padding: 6%;
    margin: 3% 25%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .button {
      width: 60%;
    }

    // Screens btwn 1250px and 1600px
    @media screen and (max-width: 1600px) {
      width: 50%;
      padding: 2% 6% 2% 6%;
      margin: 5% 25%;
    }

    // Screens btwn 776px and 1250px
    @media screen and (max-width: 1250px) {
      width: 75%;
      padding: 2% 6% 2% 6%;
      margin: 5% 12.5%;
    }

    // Screens btwn 500px and 776px
    @media screen and (max-width: 776px) {
      width: 90%;
      padding: 2% 6% 2% 6%;
      margin: 5% 5%;
    }

    // All mobile screens
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 98%;
      padding: 1% 5% 1% 5%;
      margin: 1% 1%;
    }
    & .signin-img {
      @extend .mb-16;
      width: 200px;
    }
  }
  & footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    height: fit-content !important;
    > .footer-logo {
      bottom: 0;
      position: absolute;
      text-align: center;
      width: 128px;
      margin-bottom: 16px;
    }
  }
}
