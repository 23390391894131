.aboutMeHeader{
    &_container{
        @extend .flex-row;
        @extend .justify-content-start;
        @extend .align-items-center;
        .h4{
            @extend .regular ;
         }
         img{
            width: 100%
         }

     }
    &_profileBadgeSection{
        @extend .justify-content-center; 
       @extend .align-items-center;
       @extend .flex-row;
       width :54px;
       height :54px;
       color: $Surface;
       background-color: $Copy-Muted;
       overflow: hidden;
       border-radius: 50%;
       .h3{
        color: $Surface ;

    }
  }
    &_infoSection{
        @extend .flex-column; 
        
      
  }
     

}