$Color-primary-1: #71c476;
$Surface:#FFFFFF ;
$Surface-Muted: #F9FAFD;
$Inactive: #EBEFFA;
$Muted: #DEE3F2;
$Neutral:#B9C3F3 ;
$Brand:#4951FF ;
$Highlights: #EE6F86;
$Alert:#D30465 ;
$Confirm: #5DC472;
$Copy: #2A1451;
$Copy-Muted :#6A5B85 ;
$Alert-bg:#FFE3F1 ;
$Confirm-bg:#E7F6EA ;
$Label: #828A96;
$Warning:#DA6D00 ;
$Warning-bg:#FFF0E2 ;
$Hover:#F6F6FF ;
$shadow:#2E19820D;
$Transparent:#ffffff00;
 
$Default: $Muted;
$Info: $Brand;
$Danger: $Brand;
$Success: $Confirm;
$Primary: $Copy;
 
