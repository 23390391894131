.languagesEditor {
    max-height: 860px;   
      &_container {
        max-height: 580px;
        min-height: 250px;
        overflow-y: auto;
    }

    &_header {
        @extend .flex-row;
        border-bottom: 2px solid $Inactive;
        padding-bottom: 5px;
        .p2 {
            width: 140px;
            color: $Copy-Muted;
         }
        .p2:not(:first-child){
            width: 250px;
             @extend .pl-16
             
        }
    }
}