.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparentize($color: $Copy, $amount: 0.3);
  & .modal-content-container {
    & .cardContainer_body {
      overflow-x: hidden;
      overflow-y: auto;
    }
    &.modal-extra-large {
      width: 1200px;
      & .cardContainer_body {
        max-height: 845px;
      }
    }
    &.modal-large {
      width: 1000px;
      & .cardContainer_body {
        max-height: 570px;
      }
    }
    &.modal-medium {
      width: 800px;
      & .cardContainer_body {
        max-height: 525px;
      }
    }
    &.modal-small {
      width: 640px;
      & .cardContainer_body {
        max-height: 397px;
      }
    }
  }
}
