//size of Loadingindicators component

 .extra-small {
    width: 20px;
    height: 20px;
  }
 .small {
    width: 30px;
    height: 30px;
  }
  .medium  {
    width: 40px;
    height: 40px;
  }

  .large  {
    width: 70px;
    height: 70px;
  }

.loader {
    width: 30px;
    height: 30px;
   
    &:before {
      content: "";
      width: 70px;
      height: 70px;
      position: absolute;
      
       border: 5px solid transparent;
      border-color: $Brand $Brand transparent transparent;
      border-radius: 50%;
      box-sizing: border-box;
      animation: rotate 3s ease-in-out infinite;
      transform: rotate(-200deg);
    }
    &.large:before {
       
        @extend .large ;
      }
  
    &.medium:before {
      
      @extend .medium ;
    }
  
    &.small:before {
      
      @extend .small ;
    }
    &.extra-small:before {
      
      @extend .extra-small ;
    }
  }
  
  @keyframes rotate {
    0% {
      border-width: 4px;
    }
    25% {
      border-width: 3px;
    }
    50% {
      transform: rotate(360deg);
      border-width: 4px;
    }
    75% {
      border-width: 3px;
    }
    100% {
      border-width: 4px;
    }
  }
 