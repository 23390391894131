.languagesEditorItem {
    &_container {
        @extend .flex-row;
        @extend .align-items-center;
        @extend .pl-10;
        @extend .pr-10;
        

        &:hover {
            background-color: $Surface-Muted;
        }

    }
}