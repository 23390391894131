.infoItem {
  &_container {
    display: grid;
    grid-template-columns: 40px 1fr;
    flex:1;
  }
  &_body {
    
  }
  &_label {
    color: $Neutral;
  }
   
}
