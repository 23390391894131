 

.iconLabel_container {
  
    @extend .flex-row;
    @extend .justify-content-center;
    @extend .align-items-center;
    .p1{
        color:$Copy-Muted
    }

}

 

 
