.checkbox-container {
  & label {
    display: flex;

    & .checkbox-input {
      width: 18px;
      height: 18px;
      accent-color: $Brand;
    }
    & .checkbox-label {
      @extend .ml-12;
      @extend .p1;
      color: $Copy;
    }
  }
}
