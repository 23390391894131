.relatedSkills {
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_subContainer {
    height: 82px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    width: 100%;
    flex-direction: column;
  }
  &_keyboardArrowLeft {
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    border-radius: 6px;

    @extend .cursor-pointer;
    &:hover{
        background: $Hover;

    }
  }
}

.showRelatedSkillsContianer {
    @extend .cursor-pointer;
    @extend .flex-row;
    @extend .align-items-center ;
    padding: 7px;
        padding-left: 14px;
        border-radius: 6px;
    &:hover{
        background: $Hover;
      
        
    }
  .showRelatedSkillsLabel {
    color: $Brand;
    @extend .mr-16;
  }
}
.relatedSkillsMouseTooltip{
    &:hover{
        background-color: transparent

    }
}
