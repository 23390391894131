.gridlayout-container {
  display: grid;
  column-gap: 28px;

  &.gridlayout-container-Fifty-Fifty {
    grid-template-columns: 1fr 1fr;
  }
  &.gridlayout-container-OneThird-TwoThird {
    grid-template-columns: 1fr 2fr;
  }
  &.gridlayout-container-TwoThird-OneThird {
    grid-template-columns: 2fr 1fr;
  }
  & .gridlayout-area {
    // NOP
  }
}
