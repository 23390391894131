// Flex classes

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-4 {
  flex: 4;
}

.flex-2 {
  flex: 2;
}

.grid-row {
  display: grid;
  &.gap-8 {
    column-gap: 8px;
  }
  &.full-width {
    grid-template-columns: 1fr;
  }
  &.half-width {
    grid-template-columns: 1fr 1fr;
  }
  &.third-width.two-items {
    &.two-items {
      grid-template-columns: 33% 33% !important;
    }
    &:not(.two-items) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
.two-items{
  grid-template-columns: 49% 49%;
}
.width-66percent {
  width: 66% !important;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}

// position
.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.divider {
  border: 1px solid $Inactive;
  // padding: 5px;
}
// array of values for the margin classes
$values: 0 2 3 4 5 6 8 10 12 14 16 20 24 28 30 36 40 43 56 60 72 80 155;
// this fun will create CSS margin & padding  classes classes based on a list of values  (0, 6, 11, 16, 21, 26, and 32)
// for  more exemple check style guide https://www.figma.com/file/mjDQ6bwSXJMZ0iMIfP3o8X/Sherpass-ID---Design-process?node-id=10726%3A91480&t=1B9MIUaQ1yj4d41p-0
@for $i from 1 through length($values) {
  $value: nth($values, $i);
  //margin
  .m-#{$value} {
    margin: #{$value}px;
  }
  .ml-#{$value} {
    margin-left: #{$value}px;
  }
  .mr-#{$value} {
    margin-right: #{$value}px;
  }
  .mb-#{$value} {
    margin-bottom: #{$value}px;
  }
  .mt-#{$value} {
    margin-top: #{$value}px;
  }
  .p-#{$value} {
    padding: #{$value}px !important;
  }
  .pl-#{$value} {
    padding-left: #{$value}px;
  }
  .pr-#{$value} {
    padding-right: #{$value}px;
  }
  .pb-#{$value} {
    padding-bottom: #{$value}px;
  }
  .pt-#{$value} {
    padding-top: #{$value}px;
  }
}

.circle {
  width: 4px;
  height: 4px;

  background: $Neutral;
  @extend .mr-8;
  @extend .ml-8;
}

.d-block {
  display: block;
}

.text-alert {
  color: $Alert;
}
.cursor-pointer {
  cursor: pointer;
}
.not-allowed {
  opacity: 0.5;
   cursor: not-allowed;
}
.relative{
  position: relative;
}
