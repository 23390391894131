.bold {
  font-weight: 500;
}
.regular {
  font-weight: 400;
}
.italic {
  font-style: italic;
}
.customText {
  color: $Copy;
}
.emptydataText {
  color: $Copy-Muted;
}
.h1 {
  font-size: 24px;
  @extend .bold;
}
.h2 {
  font-size: 21px;
  @extend .bold;
}
.h3 {
  font-size: 18px;
  @extend .bold;
}
.h4 {
  font-size: 16px;
  @extend .bold;
}
.p1 {
  font-size: 14px;
}
.p2 {
  font-size: 12px;
}
.p3 {
  font-size: 10px;
}

.navigation {
  @extend .p2;
  @extend .bold;
}
.notSet {
  @extend .italic;
  color: $Alert !important;
}
