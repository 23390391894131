// BoxedIcon Variables
$DefaultBoxSize: 46px;
$DefaultBoxedIconSize: 18px;

.boxIcon {
  height: $DefaultBoxSize;
  width: $DefaultBoxSize;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.boxIcon > svg {
  @extend .icon;
  @extend .icon-surface;
  height: $DefaultBoxedIconSize;
  width: $DefaultBoxedIconSize;
}

$boxColors: (
  "brand": $Brand,
  "copy": $Copy,
  "alert": $Alert,
);

// Generating all Box Colors
@each $name, $color in $boxColors {
  .boxIcon-#{$name} {
    @extend .boxIcon;
    background-color: $color;
  }
}
