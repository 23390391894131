.page-footer {
  @extend .pt-56;
  @extend .pb-8;
  @extend .pr-155;
  @extend .pl-155;
  background-color: $Copy;
  border-radius: 12px 12px 0 0;
  & p,
  & a,
  & a:focus {
    color: $Surface;
  }
  & .footer-logo {
    height: 32px;
  }
  & .footer-col {
    display: flex;
    justify-content: space-between;
    & > .footer-left {
      display: flex;
      justify-content: flex-start;
    }
    & > .footer-right {
      display: flex;
      justify-content: flex-end;
    }
    & .footer-copyright-section {
      display: flex;
    }
  }
}
