.education-list-item {
  @extend .mt-24;
  @extend .mb-12;
  display: grid;
  grid-template-columns: 46px 1fr;
  column-gap: 12px;
  .header p{
margin: 0 3px
  }

  & .education-list-item-icon {
    @extend .pt-12;
  }
  & .education-list-item-content {
    @extend .pt-12;
    @extend .pb-12;
  }
}
