// Icon Variables
$DefaultIconSize: 24px;
$SmallIconSize: 16px;

$iconColors: (
  "info": $Info,
  "brand": $Brand,
  "primary": $Primary,
  "copy-muted": $Copy-Muted,
  "surface": $Surface,
  "inactive": $Inactive,
  "label": $Label,
);

// Default Icon style
.icon {
  height: $DefaultIconSize;
  width: $DefaultIconSize;
}
// Smaller icon sizes
.icon-small {
  height: $SmallIconSize;
  width: $SmallIconSize;
}
.icon-default-size {
  height: $DefaultIconSize;
  width: $DefaultIconSize;
}

// Generating all Colors
@each $name, $color in $iconColors {
  .icon-#{$name} {
    @extend .icon;
    color: $color;
  }
}
