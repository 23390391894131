.react-datepicker__month-text {
  font-size: 14px !important;
  margin: 5px !important;
}

.react-datepicker-year-header {
  background-color: $Brand !important;
  &:first-child {
    color: $Surface;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding: 3px !important;
}

// Previous and Next button on date picker popup
.react-datepicker__navigation {
  top: 0px !important;
}

// Previous button on date picker popup
.react-datepicker__navigation-icon--previous {
  &::before {
    border-color: $Surface !important;
  }
}

// Next button on date picker popup
.react-datepicker__navigation-icon--next {
  &::before {
    border-color: $Surface !important;
  }
}
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover{
  background-color: $Hover !important;
   
}
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today{
  font-weight: 300 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
     background-color: white !important;
    color: $Brand !important;
 
}