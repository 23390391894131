.list-container {
  display: grid;
  column-gap: 1em;
  &.list-col-1 {
    grid-template-columns: 1fr;
  }
  &.list-col-2 {
    grid-template-columns: 1fr 1fr;
  }

  & .list-item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.list-show-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
