$tagBg: (
  'info': $Neutral,
  'brand': $Neutral,
  'primary': $Copy,
  'default': $Inactive,
  'surface': $Surface,
);

$tagColor: (
  'info': $Info,
  'brand': $Brand,
  'primary': $Surface,
  'default': $Primary,
  'surface': $Primary,
);

$tagBorder: (
  'info': $Neutral,
  'brand': $Neutral,
  'primary': $Copy,
  'default': $Muted,
  'surface': $Inactive,
);

$tagIcon: (
  'info': $Info,
  'brand': $Brand,
  'primary': $Surface,
  'default': $Primary,
  'surface': $Neutral,
);

.tag {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 6px;

  // types
  &.tag-small {
    &:not(.icon-tag) {
      @extend .pl-12;
    }
    &.icon-tag {
      @extend .pl-8;
    }
    &:not(.dismissable-tag) {
      @extend .pr-12;
    }
    @extend .pt-6;
    @extend .pb-6;
  }
  &.tag-large {
    &:not(.icon-tag) {
      @extend .pl-16;
    }
    &.icon-tag {
      @extend .pl-16;
    }
    &:not(.dismissable-tag) {
      @extend .pr-16;
    }
    @extend .pt-10;
    @extend .pb-10;
  }
  // color
  @each $name, $color in $tagBg {
    &.tag-#{$name} {
      background-color: $color;
      border: 1px solid map-get($map: $tagBorder, $key: $name);
      & > p {
        color: map-get($map: $tagColor, $key: $name);
      }
      & > .tag-icon > svg {
        color: map-get($map: $tagIcon, $key: $name);
      }
      &.dismissable-tag {
        & > .tag-dismisser:hover {
          cursor: pointer;
          background: transparentize(
            $color: invert($color, $weight: 100%),
            $amount: 0.75
          );
        }
      }
    }
  }
  & > .tag-icon {
    @extend .mr-8;
    display: flex;
    justify-content: center;
    & > svg {
      @extend .icon-small;
    }
  }
  & > .p1 {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &.dismissable-tag {
    & > .tag-dismisser {
      @extend .ml-8;
      @extend .mt-0;
      @extend .mb-0;
      @extend .mr-6;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 14px;
      height: 14px;

      & > svg {
        @extend .icon-small;
      }
    }
    // default positioning styles
  }
}
