.navheader-container {
  @extend .pr-60;
  @extend .pl-60;
  display: flex;
  background: $Surface;
  box-shadow: 0px 0px 30px rgba(46, 25, 130, 0.05);
  border-radius: 0px 0px 12px 12px;
  height: 88px;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  & img.header-logo {
    height: 32px;
  }
  & > .navheader-section {
    display: flex;
    align-items: center;

    &.navheader-section-left {
    }
    &.navheader-section-right {
    }
  }
}
