.multiSelectItemContainer{
    border: solid 1px $Inactive;
    border-radius: 6px;
    display: table;
    
    @extend .pt-12;
    @extend .pb-12;
    @extend .pl-16;
    @extend .pr-16;
    @extend .cursor-pointer;
}
 