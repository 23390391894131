.LanguagesSectionItem {

    &_container {
        
        @extend .p-24;
        border: 1px solid $Inactive;
        box-shadow: 0px 0px 30px rgba(46, 25, 130, 0.05);
        border-radius: 12px;
    }
    &_body {
        @extend .flex-row;
        @extend .align-items-center;
        @extend .justify-content-between;
         
    }
}